@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .contacticon {
    @apply w-10 h-10 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300;
  }
  .resumeLi{
    @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center 
    cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px];
  }
}



.active {
  color: white;
}

.card {
  position: absolute;
  perspective: 500px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front,
.back {
  position: absolute;
  border-radius: 5px;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}

.centeredaa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

